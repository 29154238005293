import React from 'react';
import { Link } from 'react-router-dom';


const Footer = () => {
    return (
        <>

        </>
    );
};

export default Footer;
