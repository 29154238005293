import React from 'react';
import { Route, Link as NavLink, Switch, BrowserRouter as Router } from 'react-router-dom';
import { bool, shape, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
 import { logoutSuccess } from '../../actions/user-action-types';


const Header = () => {


  return (
        <>


        </>
    );
};

Header.propTypes = {
    isDrawerOpen: bool.isRequired,
    title: string.isRequired,
    user: shape({ name: string.isRequired }),
  };

  Header.defaultProps = { user: null };

export default Header;
