import axios from 'axios';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ADMIN_API_PATH } from '../constant/path';
export { _fetch as fetch };
let show = false;
let session = false;

function handleError(error, reject) {
  if (!error) {
    if (show === false) {
      show = true;
      console.log('Something went wrong, Please try again');
    }
  }

  if (error) {
    let status = error.status;

    if (status === 401) {
      if (session === false) {
        session = true;
        setTimeout(() => {
          window.localStorage.clear();
          window.location.href = `/`;
        }, 1500);

        return /* toast.error */ console.log('Session expired, you are going to be logout');
      }
    }

    if (status === '404 Not Found') {
      return /* toast.error */ console.log('Internal Server Error.');
    }

    if (error.data.message === 'You are not authorised to perform this action.') {
      setTimeout(() => {
        localStorage.clear();
        window.location.href = `/`;
      }, 2000);

      return /* toast.error */ console.log(error.data.message);
    }
  }

  reject(error);

  return;
}

function handleResponse(successs, resolve) {
  resolve(successs);
  return;
}

function setMehod(method, path, body, options, params) {
  let config = {};
  if (options) {
    if (options) {
      config.headers = { ...options };
      console.log('config.headers ', config.headers);
    }
  }
  params = params ? '?' + new URLSearchParams(params).toString() : '';
  if (method === 'get' || method === 'delete') {
    return axios[method](`${path}${params}`, config);
  }
  if (method === 'post' || method === 'put') {
    return axios[method](`${path}`, body, config);
  }
}

function saveApiRequestResponse(agent_id, search_token, header, request, response, apiName, origin, destination, travel_date) {
  fetch(ADMIN_API_PATH + `saveApiRequestResponse`, {
    method: 'POST',
    body: JSON.stringify({
      search_token: search_token,
      api_name: apiName,
      request: request,
      response: response,
      agent_id: agent_id,
      api_header: header,
      origin: origin,
      destination: destination,
      travel_date: travel_date
    }),
  });
}
 

function _fetch(method, path, body, options, params) {
  return new Promise((resolve, reject) => {
    return setMehod(method, path, body, options, params)
      .then(function (response) {
        console.log('API', response)
        handleResponse(response, resolve);
        const convertedHeader = JSON.parse(body)
        
        const splitedPath = path.split('/');
        const splittedFirstArray = splitedPath.reverse();
        
        const splittedPAthFinal = splittedFirstArray[0];
        
        const rootResponse = JSON.parse(localStorage.getItem('persist:primary'));
        
        const parseRootResponse = JSON.parse(rootResponse.user) || '';
        const getAgentId = parseRootResponse.userDetail.agentId || '';
        let getSearchToken = sessionStorage.getItem('searchToken')
        console.log(convertedHeader,getSearchToken,"headerrrrrrrrrrrrrrrrrr")
        
        let origin = ''
        let destination = ''
        let travel_date = ''

        if(splittedPAthFinal == 'search') {
           getSearchToken = response.data.Response.Search_Token
           origin = convertedHeader.AirSegments[0].Origin
           destination = convertedHeader.AirSegments[0].Destination
           travel_date = convertedHeader.AirSegments[0].PreferredTime
        }

        if(splittedPAthFinal == 'CancelRequest' || splittedPAthFinal == 'ReleasePNR') {
            getSearchToken = convertedHeader.Search_Token_Id
        }
        console.warn('===========',splittedPAthFinal,'======')
         
        if (
          splittedPAthFinal == 'search' ||
          splittedPAthFinal == 'fare_rule' ||
          splittedPAthFinal == 'fare_confirmation' ||
          splittedPAthFinal == 'book' ||
          splittedPAthFinal == 'ReleasePNR' ||
          splittedPAthFinal == 'CancelRequest' ||
          splittedPAthFinal == 'ticket'
        ) {
          return {
            getAgentId,
            getSearchToken,
            splittedPAthFinal,
            response,
            origin,
            destination,
            travel_date
          }
        }
      })
      .then(({ getAgentId, getSearchToken, splittedPAthFinal, response, origin, destination, travel_date }) => {
      
        saveApiRequestResponse(
          getAgentId,
          getSearchToken,
          JSON.stringify(options),
          body,
          JSON.stringify(response.data),
          splittedPAthFinal,
          origin, 
          destination, 
          travel_date
        )
      })
      .catch(function (error) {
        handleError(error.response, reject);
        return;
      });
  });
}
