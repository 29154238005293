//balance.hoc
import * as React from 'react';
import { useSelector } from 'react-redux';
import { walletUpdate } from '../services/api/api.service';

const BalanceHOC = (Childcomponent) => {    
   return function(props) {
    const [walletdata, setWalletData] = React.useState([]);

    const getCreditTotal = (arr) => {
      let creditedAmount = 0;
      for (let eachObj of arr) {
        if (eachObj.pay_type == 'credit') {
          creditedAmount += +eachObj.amount;
        }
      }
      console.log(arr);
      console.log(creditedAmount);
      return creditedAmount;
    };
  
    const getDebitTotal = (arr) => {
      let debitedAmount = 0;
      for (let eachObj of arr) {
        if (eachObj.pay_type == 'debit') {
          debitedAmount += +eachObj.amount;
        }
      }
      console.log(arr);
      console.log(debitedAmount);
      return debitedAmount;
      // console.log(walletdata);
    };
    const getcredit = useSelector((state)=>state.user.userDetail.credit_balance)
    
  
    const getBalance = (arr) => {
      let balance = null;
      let credited = 0;
      let debited = 0;
      for(let eachdata of arr){
       if(eachdata.pay_type == 'credit'){
          credited += +eachdata.amount;
       }
       if(eachdata.pay_type == 'debit'){
          debited += +eachdata.amount;
       }
      }
       balance = credited - debited;
       return balance;
      // console.log(walletdata);
    };
    const getAgentId = useSelector((state)=>state.user.userDetail.agentId)
    React.useEffect(() => {
      walletUpdate(getAgentId).then((response) => {
        setWalletData(response.data.data);
      });
    }, [])
    return <Childcomponent {...props} walletdata={walletdata} getCreditTotal={getCreditTotal} getDebitTotal={getDebitTotal} getBalance={getBalance} getcredit={getcredit}/>
   }
}

export default BalanceHOC