import { Route, Link as NavLink, Switch, BrowserRouter as Router } from 'react-router-dom';
import  { Component } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { logoutSuccess } from '../actions/user-action-types';
import BalanceHOC from '../hoc/balance.hoc';

import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Stack } from 'react-bootstrap';



// ----------------------------------------------------------
const Header = (props) => {
  const { walletdata, getCreditTotal, getDebitTotal, getBalance ,getcredit } = props
  const dispatch = useDispatch();
  const onLogout = () => {
    dispatch(logoutSuccess());
    dispatch(push('/'));
  };
   
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid white',
    boxShadow: 24,
    borderRadius:"20px",
    p: 4,
  };
  useEffect(()=>{
    // {1000-500>0?  setOpen(true):"hello"}
  

  },[])
  const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

  const ticketsPage=(e)=>{
    e.preventDefault()
    history.push('/payment-details')
  }
  // const getFormData = (e) => {
  //   e.preventDefault();
  //   history.push('/booking');
  // };
  return (
    <div className="container_fluid">
      {/*  */}

      {/* <Button onClick={handleOpen}>Open modal</Button> */}
     <div>
     
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Stack spacing={2} direction="row">
            <Typography style={{marginLeft:"28%",padding:"3px",marginRight:"28%",color:"red",borderRadius:"20px",border:"1px solid white"}} >Insufficient Amount</Typography>
            {/* <Typography id="transition-modal-title" variant="h6" component="h2"> */}
            {/* <Button onClick={() => history.goBack()}>hello */}
            <Box style={{ marginLeft:"10%", marginTop:"20px"}}>
            <Button variant="outlined" style={{border:"1px solid green",borderRadius:"20px",marginRight:"10px"}}>
            <Link style={{textDecoration:"none",color:"grey"}} to={'/flight-search'}>Select Ticket</Link>
              </Button>
            {/* </Typography> */}
           
            {/* <Typography id="transition-modal-description" sx={{ mt: 2 }}> */}
              <Button variant="outlined" style={{border:"1px solid green",borderRadius:"20px"}}>
              <Link style={{textDecoration:"none",color:"grey"}} to={'/payment-details'}>Add Money</Link>
                </Button>
                </Box>
            {/* </Typography> */}
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </div>
      {/*  */}
      <div className="header">
        <div className="logo">
          <h1><NavLink to="/">RAHI TRAVELS</NavLink></h1>
        </div>
        <div className="nav_menu">
          <ul>
            <li><NavLink to="/flight-search">Flights Booking</NavLink></li>
            <li>
              <NavLink to="#">My Accounts</NavLink>
              <ul>
                <li>
                  <NavLink to="/booking">Bookings</NavLink>
                </li>
                <li>
                  <NavLink to="/subAgentBookings">Sub Agent Booking</NavLink>
                </li>
                <li>
                  <NavLink to="/ticket">Tickets</NavLink>
                </li>
                <li>
                  <NavLink to="/bookingQueue">Booking Queue</NavLink>
                </li>
                <li>
                  <NavLink to="/bookingCancelled">Cancellation</NavLink>
                </li>
                <li>
                  <NavLink to="/payment">Payment</NavLink>
                </li>
                <li>
                  <NavLink to="/payment-details">Add Payment</NavLink>
                </li>
                <li>
                  <NavLink to="/wallet">Wallet Summary</NavLink>
                </li>
                <li>
                  <NavLink to="/profile">Profile</NavLink>
                </li>

                <li>
                  <NavLink to="/markup">MarkUp</NavLink>
                </li>
                {/* <li>
                  <NavLink to="/customer-details">Customer</NavLink>
                </li> */}
                <li>
                  <NavLink to="/term-and-Condition">Terms & Conditions</NavLink>
                </li>
                <li>
                  <NavLink onClick={() => onLogout()}>Logout</NavLink>
                </li>
              </ul>
            </li>
            <li className="wallet_balance">
              Balance <span>INR {Math.round(getBalance(walletdata),2)}</span>
            </li>
            <li className="wallet_balance">
              {/* Credit <span>INR   {getcredit}</span> */}
              Credit <span>INR {getBalance(walletdata) >=0 ? getcredit : 0}</span>
              {/* Credit <span>INR {(getcredit) === isNaN||null ? 0 : (getcredit)}</span> */}
              {/* ({(getcredit) === isNaN ? 0 : (getcredit)}) */}
              {/* {getcredit.toString().padStart(5, '0')} */}
              {/* {getcredit.status >0 (getcredit) : 0} */}
              {/* if({getcredit}===0|| isNaN){
                return 0
              } */}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default BalanceHOC(Header);
// import { Route, Link as NavLink, Switch, BrowserRouter as Router } from 'react-router-dom';
// import React, { Component } from 'react';
// import { useDispatch } from 'react-redux';
// import { push } from 'connected-react-router';
// import { logoutSuccess } from '../actions/user-action-types';
// import BalanceHOC from '../hoc/balance.hoc';
// const Header = (props) => {
//   const { walletdata, getCreditTotal, getDebitTotal, getBalance ,getcredit } = props
//   const dispatch = useDispatch();
//   const onLogout = () => {
//     dispatch(logoutSuccess());
//     dispatch(push('/'));
//   };
//   console.log("getCreditTotalgetCreditTotalgetCreditTotalgetCreditTotalgetCreditTotalgetCreditTotalgetCreditTotalgetCreditTotalgetCreditTotal1000")
//   return (
//     <div className="container_fluid">
//       <div className="header">
//         <div className="logo">
//           <h1><NavLink to="/">RAHI TRAVELS</NavLink></h1>
//         </div>
//         <div className="nav_menu">
//           <ul>
//             <li><NavLink to="/flight-search">Flights Booking</NavLink></li>
//             <li>
//               <NavLink to="#">My Accounts</NavLink>
//               <ul>
//                 <li>
//                   <NavLink to="/booking">Bookings</NavLink>
//                 </li>
//                 <li>
//                   <NavLink to="/subAgentBookings">Sub Agent Booking</NavLink>
//                 </li>
//                 <li>
//                   <NavLink to="/ticket">Tickets</NavLink>
//                 </li>
//                 <li>
//                   <NavLink to="/bookingQueue">Booking Queue</NavLink>
//                 </li>
//                 <li>
//                   <NavLink to="/bookingCancelled">Cancellation</NavLink>
//                 </li>
//                 <li>
//                   <NavLink to="/payment">Payment</NavLink>
//                 </li>
//                 <li>
//                   <NavLink to="/payment-details">Add Payment</NavLink>
//                 </li>
//                 <li>
//                   <NavLink to="/wallet">Wallet Summary</NavLink>
//                 </li>
//                 <li>
//                   <NavLink to="/profile">Profile</NavLink>
//                 </li>

//                 <li>
//                   <NavLink to="/markup">MarkUp</NavLink>
//                 </li>
//                 {/* <li>
//                   <NavLink to="/customer-details">Customer</NavLink>
//                 </li> */}
//                 <li>
//                   <NavLink to="/term-and-Condition">Terms & Conditions</NavLink>
//                 </li>
//                 <li>
//                   <NavLink onClick={() => onLogout()}>Logout</NavLink>
//                 </li>
//               </ul>
//             </li>
//             <li className="wallet_balance">
//               Balance <span>INR {getBalance(walletdata)}</span>
//             </li>
//             <li className="wallet_balance">
//               {/* Credit <span>INR   {getcredit}</span> */}
//               Credit <span>INR {(getcredit) <=0 ? 0 : (getcredit)}</span>
//               {/* Credit <span>INR {(getcredit) === isNaN||null ? 0 : (getcredit)}</span> */}
//               {/* ({(getcredit) === isNaN ? 0 : (getcredit)}) */}
//               {/* {getcredit.toString().padStart(5, '0')} */}
//               {/* {getcredit.status >0 (getcredit) : 0} */}
//               {/* if({getcredit}===0|| isNaN){
//                 return 0
//               } */}
//             </li>
//           </ul>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default BalanceHOC(Header);

// import { Route, Link as NavLink, Switch, BrowserRouter as Router } from 'react-router-dom';
// import React, { Component } from 'react';
// import { useDispatch } from 'react-redux';
// import { push } from 'connected-react-router';
//  import { logoutSuccess } from '../actions/user-action-types';

// import BalanceHOC from '../hoc/balance.hoc';
//  const Header = (props) => {
// 	const dispatch = useDispatch();
// 	const onLogout = () => {
// 	  dispatch(logoutSuccess());
// 	  dispatch(push('/'));
// 	};
// 	return (
// 		<div className="container_fluid">
// 			<div className="header">
// 				<div className="logo"><h1>RAHI TRAVELS</h1></div>
// 				<div className="nav_menu">
// 					<ul>
// 						<li><NavLink to="#">My Accounts</NavLink>
// 							<ul>
// 								<li><NavLink to="/booking">Bookings</NavLink></li>
// 								<li><NavLink to="/ticket">Tickets</NavLink></li>
// 								<li><NavLink to="#">Booking Queue</NavLink></li>
// 								<li><NavLink to="#">Cancellation</NavLink></li>
// 								<li>
//                   <NavLink to="/payment">Payment</NavLink>
//                  </li>

// 								<li><NavLink to="/wallet">Wallet Summary</NavLink></li>
// 								<li><NavLink to="#">Profile</NavLink></li>
// 								<li><NavLink to="/markup">MarkUp</NavLink></li>
// 								<li><NavLink onClick={() => onLogout()}>Logout</NavLink></li>
// 							</ul>
// 						</li>
// 						<li className='wallet_balance'>Credit <span>INR 25000</span></li>
// 						<li className='wallet_balance'>Balance <span>INR 6000</span></li>
// 					</ul>
// 				</div>

// 			</div>
// 		</div>

// 	)
// }
// // export default Header;
// export default BalanceHOC(Header);

