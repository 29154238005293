import React from 'react';
import { Helmet } from 'react-helmet';
import { any, string } from 'prop-types';
import { useSelector } from 'react-redux';
import { USER_SCHOOL_ADMIN, USER_PARENT, USER_STUDENT, USER_TEACHER } from '../constants';
import Header from '../common/header'

const PublicLayout = ({
  children, title,
}) => {

  return (
    <div>
      <Helmet>
        <title>{`${title} - Rahi Travel`}</title>
      </Helmet>
      <Header />
      {children}

    </div>
  );
};

PublicLayout.propTypes = {
  children: any.isRequired,
  title: string.isRequired,
};

export default PublicLayout;
