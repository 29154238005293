import React from 'react';
import { bool, shape, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { toggleDrawer, toggleDrawerMenu } from '../../actions/app-action-types';
import { logoutSuccess } from '../../actions/user-action-types';
import ProfileDropDown from './profile-dropdown';

const Header = ({
  isDrawerOpen, title, user,
}) => {

  return (
    <div className="app-header header-shadow">

    </div>
  );
};



export default Header;
