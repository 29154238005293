import { fetch } from '../fetch.services';
import { API_PATH, ADMIN_API_PATH } from '../../constant/path';
import { AUTH_TOKEN } from '../../constant/token';

export const getFlightSearchResult = (options) => {
  return fetch('post', API_PATH + 'search', JSON.stringify(options), {
    Authorization: AUTH_TOKEN,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },);
};

export const getFareRule = (options) => {
  return fetch('post', API_PATH + 'fare_rule', JSON.stringify(options), {
    Authorization: AUTH_TOKEN,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });
};

export const getFareConfirmation = (options) => {
  return fetch('post', API_PATH + 'fare_confirmation', JSON.stringify(options), {
    Authorization: AUTH_TOKEN,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });
};

export const getAirportList = () => {
  return fetch('get', ADMIN_API_PATH + 'airport_list', {}, {});
};

export const savePayment = (options) => {
  return fetch('post', ADMIN_API_PATH + 'savePayment', JSON.stringify(options), {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });
};

export const saveVendor = (options) => {
  return fetch("post", ADMIN_API_PATH + 'saveVendor', JSON.stringify(options), {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  );
};


export const saveVendorMarkup = (agent_id, vendor_markup, vendor_markup_type) => {
  return fetch(
    'post',
    ADMIN_API_PATH +
    `saveVendorMarkup&agent_id=${agent_id}&vendor_markup=${vendor_markup}&vendor_markup_type=${vendor_markup_type}`,
    JSON.stringify([{ agent_id: 1, vendor_markup: 100, vendor_markup_type: 'percent' }]),
    {
      // return fetch("post", ADMIN_API_PATH + `agentDetails&agent_id=${agent_id}`, JSON.stringify(options), {
      // return fetch("post", ADMIN_API_PATH + 'saveVendor', JSON.stringify(options), {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  );
};
// 'Content-type': 'application/json; charset=UTF-8',


export const saveBookings = (options) => {
  return fetch('post', ADMIN_API_PATH + 'saveBooking', JSON.stringify(options), {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });
};
export const savePassengers = (options) => {
  return fetch('post', ADMIN_API_PATH + 'savePassenger', JSON.stringify(options), {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });
};

export const getAirlineCode = () => {
  return fetch('get', ADMIN_API_PATH + 'airlinecode', {}, {});
};

export const getBookings = (agent_id, booking_status) => {
  // return fetch("get", ADMIN_API_PATH + `agentDetails&agent_id=${agent_id}`, {}, {});
  return fetch('get', ADMIN_API_PATH + `bookings&agent_id=${agent_id}&booking_status=${booking_status}`, {}, {}); //working.
};
// https://www.rahitrip.com/api/tbo-apis.php?type=bookings&agent_id=1&booking_status=pending
// https://www.rahitrip.com/api/tbo-apis.php?type=agentDetails&agent_id=1

// export const getBookings = (booking_id, booking_status) => {
// return fetch("get", ADMIN_API_PATH + `agentDetails&agent_id=${agent_id}`, {}, {});
// return fetch('get', ADMIN_API_PATH + `bookingsDetail&booking_id=${booking_id}`, {}, {}); //working.
// return fetch('get', ADMIN_API_PATH + `bookingsDetail&booking_id=${booking_id}&booking_status=${booking_status}`, {}, {}); //working.
// return fetch('get', ADMIN_API_PATH + `bookings&agent_id=${agent_id}&booking_status=${booking_status}`, {}, {}); //working.
// };
//till
export const getTickets = (booking_id, booking_status, agent_id) => {
  // return fetch("get", ADMIN_API_PATH + `agentDetails&agent_id=${agent_id}`, {}, {});
  // return fetch('get', ADMIN_API_PATH + `bookings&agent_id=${agent_id}&booking_status=${booking_status}`, {}, {}); //working.
  return fetch('get', ADMIN_API_PATH + `bookings&booking_id=${booking_id}&booking_status=${booking_status}&agent_id=${agent_id}`, {}, {})
  //https://www.rahitrip.com/api/tbo-apis.php?type=bookings&booking_id=TESTPNR00003&booking_status=pending&agent_id=1
  //https://www.rahitrip.com/api/tbo-apis.php?type=bookings&booking_id=TESTPNR00003&booking_status=pending&agent_id=1
};

export const getAgentAddress = (agent_id) => {
  return fetch('get', ADMIN_API_PATH + `agentDetails&agent_id=${agent_id}`, {}, {})
}

export const passengerdetailsbybookingid = (booking_id) => {
  return fetch('get', ADMIN_API_PATH + `passengerdetailsbybookingid&booking_id=${booking_id}`, {}, {});
};

export const getBookingDetails = (options) => {
  return fetch('post', API_PATH + 'GetBookingDetails', JSON.stringify(options), {
    Authorization: AUTH_TOKEN,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });
};

export const getTicketDetails = (options) => {
  return fetch('post', API_PATH + 'ticket', JSON.stringify(options), {
    Authorization: AUTH_TOKEN,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });
};

export const BookingTicket = (options) => {
  return fetch('post', API_PATH + 'book', JSON.stringify(options), {
    Authorization: AUTH_TOKEN,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });
};

export const GetTicket = (options) => {
  return fetch('post', API_PATH + 'ticket', JSON.stringify(options), {
    Authorization: AUTH_TOKEN,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });
};
export const vendorData = (agent_id, booking_status) => {
  return fetch(
    'get',
    ADMIN_API_PATH + `agentDetails&agent_id=${agent_id}&booking_status=${booking_status}`,
    { agent_id },
    { booking_status },
  );
};


export const getvendorData = (agent_id) => {
  return fetch("get", ADMIN_API_PATH + `agentDetails&agent_id=${agent_id}`, {}, {});
};

export const setvendorData = (agent_id, markup, booking_status) => {
  return fetch("get", ADMIN_API_PATH + `saveVendorMarkup&agent_id=${agent_id}&vendor_markup=${markup}&vendor_markup_type=${booking_status}`, {}, {})
};

export const paymentSummary = (agent_id) => {
  return fetch("get", ADMIN_API_PATH + `paymentDetails&&agent_id=${agent_id}`, {}, {})
}


export const walletUpdate = (agent_id) => {
  return fetch("get", ADMIN_API_PATH + `walletDetails&&agent_id=${agent_id}`, {}, {})
}
// profile
// export const getAgentAddress=(agent_id)=>{
//   return fetch('get',ADMIN_API_PATH + `agentDetails&agent_id=${agent_id}`,{},{})
// }


export const updateAgentAddress = (agent_id) => {
  return fetch('get', ADMIN_API_PATH + `updateVendor&agent_id=${agent_id}`, {}, {})
}

// export const getBookingss = (agent_id, booking_status) => {
//   return fetch('get', ADMIN_API_PATH + `agentDetails&agent_id=${agent_id}&booking_status=${booking_status}`, {}, {});
//   // return fetch("get", ADMIN_API_PATH + `bookings&agent_id=${agent_id}&booking_status=${booking_status}`, {}, {});//working.
// };

// https://www.rahitrip.com/api/tbo-apis.php?type=subAgentList&agent_id=1
export const subAgentIdMobile = (agent_id) => {
  return fetch('get', ADMIN_API_PATH + `subAgentList&agent_id=${agent_id}`, {}, {})
}
export const allBookingData = (booking_id) => {
  return fetch('post', ADMIN_API_PATH + `saveBooking=${booking_id}`, {}, {})
}
// https://www.rahitrip.com/api/tbo-apis.php?type=airlineMarkup&airline_code=2B
export const getFlightCommission = (airline_code) => {
  return fetch('get', ADMIN_API_PATH + `airlineMarkup&airline_code=${airline_code}`, {}, {})
}

export const getAgentMarkUp = (agent_id) => {
  return fetch('get', ADMIN_API_PATH + `agentMarkup&agent_id=${agent_id}`, {}, {})
}

export const Cancelticket = (options) => {
  return fetch('post', API_PATH + 'CancelRequest', JSON.stringify(options), {
    Authorization: AUTH_TOKEN,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });
};

// https://travelxmlapi.com/v1/airservice/rest/CancelRequest
export const CancelationStatus = (booking_id) => {
  return fetch("get", ADMIN_API_PATH + `updateBookingStatus&booking_id=${booking_id}&booking_status=rejected`, {}, {})
}

export const confirmStatus = (booking_id) => {
  return fetch("get", ADMIN_API_PATH + `updateBookingStatus&booking_id=${booking_id}&booking_status=confirmed`, {}, {})
}


export const releasepnnr = (options) => {
  return fetch('post', API_PATH + 'ReleasePNR', JSON.stringify(options), {
    Authorization: AUTH_TOKEN,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });
};
