import React from 'react';

// Common
const NotFound = React.lazy(() => import('../pages/not-found'));

const ForbiddenAccess = React.lazy(() => import('../pages/forbidden-access'));

// User onboarding
const Login = React.lazy(() => import('../pages/user/login'));
const ForgotPassword = React.lazy(() => import('../pages/user/forgot-password'));
const ChangePassword = React.lazy(() => import('../pages/user/change-password'));
const MyProfile = React.lazy(() => import('../pages/user/my-profile'));
const EditProfile = React.lazy(() => import('../pages/user/edit-profile'));
const CustomerRegistration = React.lazy(() => import('../pages/user/registration'));


const FlightSearch = React.lazy(() => import('../pages/ticketBookings/flightsearch'));
const Flightresult = React.lazy(() => import('../pages/ticketBookings/flightresult'));
const Passengerdetails = React.lazy(() => import('../pages/ticketBookings/passengerdetails'));

// Book
const Book = React.lazy(() => import('../pages/ticketBookings/bookingReviews'));
const BookingReview = React.lazy(() => import('../pages/ticketBookings/bookingReviews'));
const BookingConfirmation = React.lazy(() => import('../pages/ticketBookings/bookingConfirmation'));
const BookingError = React.lazy(() => import('../pages/ticketBookings/bookingError'));
const passenger_data = React.lazy(() => import('../pages/bookings/passenger_details'));

// Tickets
const TicketListing = React.lazy(() => import('../pages/ticket/ticketConfirmed'));
const TicketDetails = React.lazy(() => import('../pages/ticket/ticketDetails'));
const ViewTicket = React.lazy(() => import('../pages/ticket/viewTickets'));

// const ViewCandidate = React.lazy(() => import('../pages'));

// ViewCandidateSubAgentBookings
const ViewCandidateSubAgentBookings=React.lazy(()=>import("../pages/bookings/subAgentBookings"))
//Tickets Confirmed
const ViewCandidateBooked=React.lazy(()=>import('../pages/ticket/bookingQueue'))

// Ticket Cancelled
const ViewCandidateCancelled=React.lazy(()=>import('../pages/ticket/cancellation'))


// Bookings
const BookingListing = React.lazy(() => import('../pages/bookings'));
const BookingDetails = React.lazy(() => import('../pages/bookings/bookingDetails'));

// Invoice
const Invoices = React.lazy(() => import('../pages/invoices'));
const CancelInvoices = React.lazy(() => import('../pages/invoices/cancellationInvoice'));

const QueueListing = React.lazy(() => import('../pages/changeRequest'));
const QueueDetails = React.lazy(() => import('../pages/changeRequest/bookingQueueDetails'));

//Concellation
const Cancellationticket = React.lazy(()=>import('../pages/bookings/CancellationTicket'))

//ReleasePnr
const Releaspnr = React.lazy(()=>import('../pages/bookings/Releasepnr'))
// Agency Registration

const PersonalDetails = React.lazy(() => import('../pages/registration/personalDetails'));
const ReviewAndSave = React.lazy(() => import('../pages/registration/ReviewAndSave'));

// Payment Details
const PaymentDetails = React.lazy(() => import('../pages/PaymentDetails'));

// const ticket = React.lazy(()=>import("../pages/bookings/Ticket")) 
const ticket = React.lazy(()=>import("../pages/bookings/Invoice")) 
// Invoice
const Invoice = React.lazy(()=> import("../pages/bookings/Tickets"))
// MarksUp
const markup=React.lazy(()=>import('../components/mark_up/markup'))

const MarksUp = React.lazy(() => import('../pages/marksup'));

const BasicTable = React.lazy(()=>import("../components/Payment/walletdetails.js"))

// payment

// const payment = React.lazy(()=>import("../components/Payments/payment"))
const Wallet = React.lazy(()=>import("../components/WalletSummary/wallet.js"))
// /profile
const Profile = React.lazy(()=>import("../components/Profile/profile"))
//Customer
// const Customerdetail = React.lazy(()=>import("../components/CustomerDetail/customerDetails"))  

const Customer  = React.lazy(()=>import("../components/CustomerDetail/customerDetails"))

//terms&Conditions

const TermCondition = React.lazy(()=>import("../components/TermCondition/term_condition"))

//chnagepassword

const changepassword = React.lazy(()=>import("../components/ChangePassword/changepassword"))

const configureRoutes = () => {
  const routes = [
    {
      component: Login,
      exact: true,
      path: '/',
      title: 'Rahi Trip',
      type: 'public',
    },
    {
      component: changepassword,
      exact: true,
      path: '/changepassword',
      title: 'Change-Password',
      type: 'public',
    },
    
    {
      component: TermCondition,
      exact: true,
      path: '/term-and-Condition',
      title: 'Term Condition',
      type: 'private',
    },
    {
      component: ForgotPassword,
      exact: true,
      path: '/forgot-password',
      title: 'Forgot Password',
      type: 'public',
    },
    {
      component: Customer,
      exact: true,
      path: '/customer-details',
      title: 'Customer-Detail',
      type: 'public',
    },
    {
      component: CustomerRegistration,
      exact: true,
      path: '/customer-registration',
      title: 'Customer Registration',
      type: 'public',
    },
    {
      component: FlightSearch,
      exact: true,
      path: '/flight-search',
      title: 'Search Flight',
      type: 'private',
    },
    {
      component: Flightresult,
      exact: true,
      path: '/flight-result',
      title: 'Search Result',
      type: 'private',
    },
    {
      component: Passengerdetails,
      exact: true,
      path: '/passenger-details',
      title: 'Passenger Details',
      type: 'private',
    },
    {
      component: passenger_data,
      exact: true,
      path: '/booking/passenger/:booking_id',
      title: 'Passenger Data',
      type: 'private',
    },
    // ======================================================================
    // getAgentId
    {
      component: ticket,
      exact: true,
      path: '/booking/ticket/:booking_id/:booking_status/:getAgentId',
      // path: '/booking/ticket/:booking_id',
      title: 'Ticket',
      type: 'private',
    },
    {
      component: Cancellationticket,
      exact: true,
      path: '/booking/Cancellationticket/:booking_id/:booking_status/:getAgentId/:search_token',
      title: 'Ticket',
      type: 'private',
    },
    // invoice
    {
      component: Invoice,
      exact: true,
      path: '/booking/ticket/Invoice/:booking_id/:booking_status/:getAgentId',
      // path: '/booking/ticket/:booking_id',
      title: 'Ticket',
      type: 'private',
    },
    
    {
      component: TicketListing,
      exact: true,
      path: '/ticket',
      title: 'Ticket Queue',
      type: 'private',
    },
    //TicketConfirmed
    {
      component: ViewCandidateBooked,
      exact: true,
      path: '/bookingQueue',
      title: 'Ticket Queue',
      type: 'private',
    },
    //releasePNr
    {
      component: Releaspnr,
      exact: true,
      path: '/booking-release/:booking_id/:booking_status/:getAgentId/:search_token',
      // path: '`/booking-release/`+ booking_id +'/'+ booking_status + '/' + agent_id',
      title: 'Ticket Queue',
      type: 'private',
    },
    // ViewCandidateCancelled
    {
      component: ViewCandidateCancelled,
      exact: true,
      path: '/bookingCancelled',
      title: 'Ticket Queue',
      type: 'private',
    },
    // ViewCandidateSubAgentBookings
    {
      component: ViewCandidateSubAgentBookings,
      exact: true,
      path: '/subAgentBookings',
      title: 'Ticket Queue',
      type: 'private',
    },
    // getAgentId ,booking_id
    {
      component: TicketDetails,
      exact: true,
      path: '/ticket-details/:search_id/:pnr/:booking_id',
      title: 'Ticket Queue',
      type: 'private',
    },
    {
      component: BookingListing,
      exact: true,
      path: '/booking',
      title: 'Booking Confirmed',
      type: 'private',
    },
    {
      component: BookingDetails,
      exact: true,
      path: '/booking-details/:search_id/:pnr/:booking_id',
      title: 'Passenger Details',
      type: 'private',
    },
    {
      component: QueueListing,
      exact: true,
      path: '/queue',
      title: 'Ticket Queue',
      type: 'private',
    },
    {
      component: QueueDetails,
      exact: true,
      path: '/queue-details/:search_id/:pnr/:booking_id',
      title: 'Passenger Details',
      type: 'private',
    },
    {
      component: ViewTicket,
      exact: true,
      path: '/tickets/:search_id/:pnr/:booking_id',
      title: 'Tickets',
      type: 'private',
    },
    {
      component: Invoices,
      exact: true,
      path: '/invoices/:search_id/:pnr/:booking_id',
      title: 'Invoices',
      type: 'private',
    },
    {
      component: CancelInvoices,
      exact: true,
      path: '/cancel-invoices/:search_id/:pnr/:booking_id',
      title: 'Invoices',
      type: 'private',
    },
    {
      component: Book,
      exact: true,
      path: '/book',
      title: 'Book Tickets',
      type: 'private',
    },
    {
      component: BookingReview,
      exact: true,
      path: '/booking-review',
      title: 'Booking Confirmation',
      type: 'private',
    },
    {
      component: BookingConfirmation,
      exact: true,
      path: '/booking-confirmation',
      title: 'Booking Confirmation',
      type: 'private',
    },
    {
      component: BookingError,
      exact: true,
      path: '/booking-error',
      title: 'Booking Error',
      type: 'private',
    },
    {
      component: PersonalDetails,
      exact: true,
      path: '/register',
      title: 'Personal Details',
      type: 'public',
    },
    {
      component: ReviewAndSave,
      exact: true,
      path: '/review-save',
      title: 'Review And Save',
      type: 'public',
    },
    {
      component: PaymentDetails,
      exact: true,
      path: '/payment-details',
      title: 'Payment Details',
      type: 'private',
    },
    {
      component: MarksUp,
      exact: true,
      path: '/marksup',
      title: 'Marks Up',
      type: 'private',
    },
    {
      component: markup,
      exact: true,
      path: '/markup',
      title: 'Mark-Up',
      type: 'private',
    },
    // General routes
    {
      component: ForbiddenAccess,
      exact: true,
      path: '/forbidden-access',
      title: '403 Forbidden Access',
      type: 'error',
    },
    {
      component: BasicTable,
      exact: true,
      path: '/payment',
      title: 'wallet',
      type: 'private',
    },

{
      component: Wallet,
      exact: true,
      path: '/wallet',
      title: 'wallet',
      type: 'private',
    },
    {
      component: Profile,
      exact: true,
      path: '/profile',
      title: 'wallet',
      type: 'private',
    },
    {
      component: NotFound,
      exact: true,
      path: '*',
      title: '404 Not Found',
      type: 'error',
    }
    
  ];

  return routes;
};

export default configureRoutes;
